import React, {Component} from 'react';
import Group from "../models/Group";

class MakeGroups  extends Component{
    constructor(props) {
        super(props);
        props.app.updatePathName(props.params.match.url);
        let nb_groups = parseInt(props.params.match.params.nb);
        this.state = {
            app: props.app,
            nb_groups: nb_groups,
        }
        const promise = new Promise((resolve, reject) => {
            if (this.buildGroups(nb_groups)) {
                resolve();
            }
        })

        promise.then(() => {
            return  this.aleasJactaEs();
        }).then((students) => {
            let groups = this.state.app.state.database.selectGroups();

            Array.prototype.forEach.call(students, (student, key) => {
                console.log(key, groups);
                student.group_id = groups[key%(groups.length)].id;
                this.state.app.state.database.modifyStudent(student);
            })
        }).then(() => {
            this.state.app.redirectToViewIfCompleted();
        })

    }

    buildGroups(nb_groups) {
        this.state.app.state.database.deleteAllGroups();
        for (let i = 1; i <= nb_groups; i++) {
            let group = new Group();
            group.setColorByPosition(i);
            this.state.app.state.database.addGroup(group);
        }
        return true;
    }

    aleasJactaEs() {
        let students = this.state.app.state.database.selectStudents();
        let keys = [];
        let new_students = [];
        while(!this.allKeysAreHere(keys, students.length)) {
            let key = Math.floor(Math.random() * students.length);
            if (!this.keyIsInArray(keys, key)) {
                keys.push(key);
            }
        }
        Array.prototype.forEach.call(keys, (key) => {
           new_students.push(students[key]);
        });
        return new_students;
    }

    allKeysAreHere(keys, length) {
        let max_key = length - 1;
        let all_keys_are_ok = true;
        for (let i = 0; i < length; i++) {
            if (!this.keyIsInArray(keys, i)) {
                all_keys_are_ok = false;
            }
        }
        return all_keys_are_ok;
    }

    keyIsInArray(keys, key) {
        let keys_contains_this = false;
        Array.prototype.forEach.call(keys, (tested_key, position) => {
            if (tested_key === key) {
                keys_contains_this = true;
            }
        });
        return keys_contains_this;
    }

    render() {
        return (
            <div>

            </div>);
    }
}

export default MakeGroups;
import React, {Component} from 'react';

class PageNotFound  extends Component{
    constructor(props) {
        super(props);
        props.app.updatePathName('/404')
        this.state = {
            app: props.app,
        }
    }

    render() {
        return (
            <div>
                <p className={'red center big'}>404</p>
                <p className={'center'}>Page non trouvée</p>
            </div>);
    }
}

export default PageNotFound;
import React, {Component} from 'react';
import StudentFormComponent from "../components/SudentFormComponent";

class EditPage extends Component{
    constructor(props) {
        super(props);
        props.app.updatePathName('/');
        this.state = {
            app: props.app,
            nb_groups: props.app.state.database.selectGroups() > 0 ? props.app.state.database.selectGroups() : null,
        }
    }

    howManyGroupCanBeCreated() {
        return Math.floor(this.state.app.state.database.selectStudents().length / 2);
    }

    handleChangeNbGroupes(event) {
        let nb_groups = event.target.value;
        if (this.howManyGroupCanBeCreated() < 1) {
            this.setState({nb_groups: null});
            return;
        }
        if (nb_groups === '') {
            this.setState({nb_groups: null});
            return;
        }
        nb_groups = parseInt(nb_groups);
        if (nb_groups < 1) {
            this.setState({nb_groups: null});
            return;
        }
        if (nb_groups > this.howManyGroupCanBeCreated()) {
            nb_groups = this.howManyGroupCanBeCreated();
        }
        this.setState({
            nb_groups: nb_groups
        });
    }

    handleValidateForm(event) {
        if (this.state.nb_groups === null || this.state.nb_groups < 1 || this.state.nb_groups > this.howManyGroupCanBeCreated()) {
            return;
        }
        this.state.app.makeGroups(this.state.nb_groups);
    }

    render() {
        return (
            <div>
                <h2>Former des groupes d'apprenants</h2>
                {
                    this.state.app.state.database.selectStudents().map((student) => {
                        return <StudentFormComponent app={this.state.app} key={student.id} student={student} />
                    })
                }
                    <StudentFormComponent app={this.state.app} />
                {
                    this.howManyGroupCanBeCreated() > 0 ?
                        (
                            <React.Fragment>
                                <div>
                                    Vous pouvez composer
                                    {
                                        this.howManyGroupCanBeCreated() === 1 ?
                                            (<React.Fragment > <b>un</b> seul groupe</React.Fragment>) :
                                            <React.Fragment > de 1 à {this.howManyGroupCanBeCreated()} groupes</React.Fragment>
                                    }
                                </div>
                                <div className={'student-form'}>
                                    <div>
                                        <label>Nombre de groupes</label>
                                        <input
                                            type={'number'}
                                            value={this.state.nb_groups === null ? '' : this.state.nb_groups}
                                            onChange={this.handleChangeNbGroupes.bind(this)}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : ''
                }
                {
                    (this.state.nb_groups !== null) ? (
                        <div className={'validate-btn-container'}>
                            <button onClick={this.handleValidateForm.bind(this)}>Former les groupes</button>
                        </div>
                    ) : ''
                }
            </div>
        );
    }
}

export default EditPage;
import Student from "../models/Student";
import Group from "../models/Group";

export default class Database {

    ms2d_db = {};
    constructor() {
        this.ms2d_db = this.getDatabase();
    }

    getDatabase() {
        let database = JSON.parse(localStorage.getItem(this.KEY_DB_LS));

        if (database === null || typeof database.student === 'undefined' || typeof database.group === 'undefined') {
            this.initDatabase();
        } else {
            this.ms2d_db = database;
        }
        return this.ms2d_db;
    }

    selectStudents() {
        if (typeof this.ms2d_db.student === 'undefined') {
            return [];
        }
        let students = [];
        Array.prototype.forEach.call(this.ms2d_db.student, (student_data) => {
            let student = new Student();
            student.family_name = student_data.family_name;
            student.first_name = student_data.first_name;
            student.id = student_data.id;
            students.push(student);
        });
        return students;
    }
    studentExists(new_student) {
        let result = false;
        Array.prototype.forEach.call(this.ms2d_db.student, (student) => {
            if (
                new_student.family_name.toUpperCase() === student.family_name.toUpperCase()
                && new_student.first_name.toUpperCase() === student.first_name.toUpperCase()
                && new_student.id !== student.id
            ) {
                result = true;
            }
        });
        return result;
    }

    addStudent(new_student) {
        if (new_student instanceof Student && new_student.id === null) {
            let students = this.ms2d_db.student;
            new_student.id = this.getNextStudentId();
            students.push(new_student);
            this.ms2d_db.student = students;

            this.storeDatabase();
        }
    }

    modifyStudent(new_student) {
        if (new_student instanceof Student && new_student.id !== null) {
            let new_students = [];
            Array.prototype.forEach.call(this.ms2d_db.student, (student) => {
               if (student.id === new_student.id) {
                   new_students.push(new_student);
               } else {
                   new_students.push(student);
               }
            });
            this.ms2d_db.student = new_students;

            this.storeDatabase();
        }
    }

    deleteStudentById(student_id) {
        let new_students = [];
        Array.prototype.forEach.call(this.ms2d_db.student, (student) => {
            if (student.id !== student_id) {
                new_students.push(student);
            }
        });
        this.ms2d_db.student = new_students;

        this.storeDatabase();
    }

    getNextStudentId() {
        let students = this.ms2d_db.student;
        let id = 1;
        Array.prototype.forEach.call(this.ms2d_db.student, (student) => {
            if (student.id >= id) {
                id = student.id + 1;
            }
        });
        return id;
    }

    selectGroups() {
        let groups = [];
        Array.prototype.forEach.call(this.ms2d_db.group, (group_data) => {
            let group = new Group();
            group.id = group_data.id;
            group.color = group_data.color;
            Array.prototype.forEach.call(this.ms2d_db.student, (student_data) => {
                if (student_data.group_id === group.id) {
                    let student = new Student();
                    student.setFirstName(student_data.first_name);
                    student.setFamilyName(student_data.family_name);
                    student.id = student_data.id;
                    student.group_id = student_data.group_id;
                    group.addStudent(student);
                }
            });
            groups.push(group);
        })
        // Constituer les groupes et affecter les students
        return groups;
    }

    deleteAllGroups() {
        this.ms2d_db.group = [];
        this.storeDatabase();
    }

    findGroupById(id_group) {
        let selected_group = null;
        Array.prototype.forEach.call(this.ms2d_db.group, (group) => {
            if (group.id === id_group) {
                selected_group = group;
            }
        })
        return selected_group;
    }

    addGroup(new_group) {
        if (new_group instanceof Group && new_group.id === null) {
            let groups = this.ms2d_db.group;
            new_group.id = this.getNextGroupId();
            groups.push(new_group);
            this.ms2d_db.group = groups;

            this.storeDatabase();
        }
    }

    getNextGroupId() {
        let groups = this.ms2d_db.group;
        let id = 1;
        Array.prototype.forEach.call(this.ms2d_db.group, (group) => {
            if (group.id >= id) {
                id = group.id + 1;
            }
        });
        return id;
    }

    isEmpty() {
        if (typeof this.ms2d_db.student === 'undefined' || typeof this.ms2d_db.group === 'undefined') {
            return true;
        }
        if (this.ms2d_db.student.length === 0) {
            return true;
        }
        return false;
    }

    isCompleted() {
        if (this.ms2d_db.student.length === 0) {
            return false;
        }
        let is_completed = true;
        Array.prototype.forEach.call(this.ms2d_db.student, (student) => {
            if (student.group_id === null) {
                is_completed = false;
            }
            if (this.findGroupById(student.group_id) === null) {
                is_completed = false;
            }
        })
        return is_completed;
    }

    initDatabase() {
        this.ms2d_db = {
            student: [],
            group: [],
        };
        this.storeDatabase();
    }

    storeDatabase() {
        localStorage.setItem(this.KEY_DB_LS, JSON.stringify(this.ms2d_db));
    }

    KEY_DB_LS = 'MS2D_DATABASE';
}
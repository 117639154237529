import React, {Component} from 'react';
import Student from "../models/Student";

class StudentFormComponent extends Component{

    alerte_msg = 'Attention, un apprenant existe déjà avec le même nom et le même prénom';
    constructor(props) {
        super(props);
        let student = props.student ? props.student : new Student();
        let alerte = props.app.state.database.studentExists(student) ? this.alerte_msg : null;
            this.state = {
            app: props.app,
            student: student,
            nom: props.student ? props.student.family_name : '',
            prenom: props.student ? props.student.first_name : '',
            alerte: alerte,
        }
        this.form_focus = React.createRef();
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({

        });
    }

    componentDidMount() {
        if (this.state.student.id === null) {
            this.form_focus.current.focus();
        }
    }

    handleChangeNom(event) {
        this.setState({
            nom: event.target.value
        });
        this.state.student.setFamilyName(event.target.value);
    }

    handleChangePrenom(event) {
        this.setState({
            prenom: event.target.value
        });
        this.state.student.setFirstName(event.target.value);
    }

    validateStudent() {
        if (this.state.app.state.database.studentExists(this.state.student)) {
            this.setState({alerte: this.alerte_msg});
            return;
        }
        this.setState({alerte: null});
        if (this.state.student.isValid() && !this.state.app.state.database.studentExists(this.state.student)) {
            if (this.state.student.id === null) {
                this.state.app.state.database.addStudent(this.state.student);
            } else {
                this.state.app.state.database.modifyStudent(this.state.student);
            }
            this.state.app.updateNbStudents();
        }
    }

    deleteStudent(event) {
        if (window.confirm(`Voulez-vous vraiment supprimer cette entrée ?`)) {
            this.state.app.state.database.deleteStudentById(parseInt(event.target.getAttribute('data-id')));
            this.state.app.updateNbStudents();
        }
    }

    render() {
        return (
            <div className={`student-form ${this.state.student.id === null ? 'create' : 'registred'}`}>
                <div>
                    <label>Nom de l'apprenant</label>
                    <input
                        type={'text'}
                        ref={this.form_focus}
                        value={this.state.nom}
                        onChange={this.handleChangeNom.bind(this)}
                        onBlur={this.validateStudent.bind(this)}
                        title={this.state.student.id === null ?
                            `Saisir le nom du nouvel apprenant` :
                            `Modifier lme nom de l'apprenant ${this.state.student.family_name} ${this.state.student.first_name}`
                        }
                    />
                </div>
                <div>
                    <label>Prénom de l'apprenant</label>
                    <input
                        type={'text'}
                        value={this.state.prenom}
                        onChange={this.handleChangePrenom.bind(this)}
                        onBlur={this.validateStudent.bind(this)}
                    />
                </div>
                <div className={'btn-container'}>
                {
                    this.state.alerte !== null ?
                        <span
                            className={'icon warning orange'}
                            title={this.state.alerte}
                        ></span> : ''
                }
                {
                    this.state.student.id !== null ?
                        <span
                            className={'icon times red'}
                            title={`Supprimer l'entrée ${this.state.nom} ${this.state.prenom}`}
                            data-id={this.state.student.id}
                            onClick={this.deleteStudent.bind(this)}
                        ></span> : ''
                }
                </div>
            </div>

        );
    }
}

export default StudentFormComponent;
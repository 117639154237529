import React, {Component} from 'react';

class GroupComponent extends Component{

    constructor(props) {
        super(props);
        this.state = {
            group: props.group,
        }
    }

    render() {
        return (
            <div className={'group-unit'} style={{borderColor: this.state.group.color}}>
                <div className={'header'} style={{backgroundColor: this.state.group.color}}>
                    <span>Groupe {this.state.group.id}</span>
                    <span className={'infos'}>{this.state.group.students.length} apprenants</span>
                </div>
                <div className={'content'} style={{color: this.state.group.color}}>
                    { this.state.group.students.map((student) => {
                        return (<p key={student.id}>{student.family_name.toUpperCase()} {student.first_name}</p>);
                    })}
                </div>
            </div>
        );
    }
}

export default GroupComponent;
export default class Student {
    id = null;
    first_name = '';
    family_name = '';
    group_id;
    group;

    constructor() {

    }

    setFirstName(first_name) {
        this.first_name = first_name;
    }

    setFamilyName(family_name) {
        this.family_name = family_name;
    }

    setGroup(group) {
        group.addStudent(this);
        this.group = group;
    }

    isValid() {
        return this.first_name.length >= 2 && this.family_name.length >= 2;
    }
}
import React, {Component} from 'react';
import GroupComponent from "../components/GroupComponent";
class ViewPage extends Component{
    constructor(props) {
        super(props);
        props.app.updatePathName('/view');
        this.state = {
            app: props.app,
            groups: props.app.state.database.selectGroups(),
        }
    }

    render() {
        return (
            <div>
                <h2>Groupes d'étudiants</h2>
                <div className={'group-container'} >
                    { this.state.groups.map((group) => {
                        return <GroupComponent group={group} key={group.id}/>
                    })}
                </div>
            </div>
        );
    }
}

export default ViewPage;
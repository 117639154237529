export default class Group {
    id = null;
    color;
    students = [];

    constructor() {

    }

    addStudent(student) {
        this.students.push(student);
    }

    resetStudents() {
        this.students = [];
    }

    setColorByPosition(nb_group_position) {
        let color_id = nb_group_position%6;
        this.color = this.colors[color_id];
    }

    colors = {
        0: '#333300',
        1: '#330000',
        2: '#330033',
        3: '#000033',
        4: '#003333',
        5: '#003300',
    };
}
import React, {Component} from "react";
import {BrowserRouter, Switch, Route, Link, Redirect} from 'react-router-dom';
import PageNotFound from "./pages/not-found";
import Database from "./database/Database";
import EditPage from "./pages/edit";
import ViewPage from "./pages/view";
import MakeGroups from "./pages/make-groups";

class AppComponent extends Component {
    constructor(props) {
        super(props);
        let database = new Database();
        this.state = {
            database: database,
            pathname: window.location.pathname,
            nb_students: database.selectStudents().length,
            redirect_to: null,
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({

        });
    }

    updatePathName(pathname) {
        if (this.state.pathname !== pathname) {
            this.setState({
                pathname: pathname,
            })
        }
    }

    updateNbStudents() {
        if (this.state.database.selectStudents().length !== this.state.nb_students) {
            this.setState({
                nb_students: this.state.database.selectStudents().length,
            });
        }
    }

    resetDatabase() {
        if (window.confirm('Etes-vous certain de vouloir réinitialiser les données ?')) {
            this.state.database.initDatabase();
            this.updateNbStudents();
            this.setState({
                redirect_to: '/'
            })
        }
    }

    makeGroups(nb_groups) {
        this.setState({
            redirect_to: `/make-groups/${nb_groups}`
        })
    }

    redirectToViewIfCompleted() {
        if (this.state.database.isCompleted()) {
            this.setState({
                redirect_to: `/view`
            })
        }
    }

    conponent
    render() {
        return (
            <BrowserRouter>
                <header>
                    <h1>MS2D</h1>
                </header>
                <section>
                    {this.state.redirect_to ? <Redirect to={this.state.redirect_to} /> : ''}
                    <div className={'navigate'}>
                        {this.state.pathname !== "/" ? <Link to="/">Modifier les groupes</Link> : ''}
                        {this.state.database.isEmpty() ? '' : <a className={'red'} href={'#root'} onClick={this.resetDatabase.bind(this)}>Réinitialiser</a>}
                    </div>
                    <Switch>
                        <Route exact path="/" component={() => <EditPage app={this} reset={false} />} />
                        <Route exact path="/reset" component={() => <EditPage app={this} reset={true} />} />
                        <Route exact path="/view" component={() => <ViewPage app={this} />} />
                        <Route exact path="/make-groups/:nb" component={(params) => <MakeGroups app={this} params={params} />} />
                        <Route component={() => <PageNotFound app={this} />} />
                    </Switch>
                </section>
                <footer>
                    <p>D Girault</p>
                </footer>
            </BrowserRouter>
        );
    }
}

export default AppComponent;